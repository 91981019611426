import { projects } from "./projects";

const commands: Map<string, string> = new Map<string, string>();

commands.set("motd", motdText());
commands.set("whoami", aboutText());
commands.set("touch", "Why would you touch anything?");
commands.set("rm", "Why would you remove anything?");
commands.set("cat", "Here's a cute cat for you! 😊");
commands.set("about", aboutText());
commands.set("pwd", "/");
commands.set("date", new Date().toLocaleString());
// commands.set("projects", projectsText());
commands.set("kali", kaliLogo());
// commands.set("ls", projectsText());
commands.set("github", openLink("https://github.com/vanshdamania"));
commands.set("linkedin", openLink("https://www.linkedin.com/in/vanshdamania/"));
commands.set("email", openLink("mailto:vanshdamania04@gmail.com"));
commands.set("resume", "Redirecting to my resume...");
commands.set(
  "tryhackme",
  openLink("https://tryhackme.com/p/vanshdamania/")
);
commands.set(
  "socials",
  "Usage: [command]<br><br>github, linkedin, email, tryhackme"
);
commands.set("help", helpText());

export const getCommandByName = (name: string): string => {
  name = name.trim().toLowerCase().split(" ")[0];

  // commands that require redirecting
  switch (name) {
    case "github":
      window.open("https://github.com/vanshdamania", "_blank");
      break;
    case "linkedin":
      window.open("https://www.linkedin.com/in/vanshdamania/", "_blank");
      break;
    case "email":
      window.open("mailto:vanshdamania04@gmail.com", "_blank");
      break;
    case "tryhackme":
      window.open("https://tryhackme.com/p/vanshdamania/", "_blank");
      break;
    case "cat":
      window.open("https://cataas.com/cat/cute", "_blank");
      break;
    case "resume":
      window.open("https://drive.google.com/file/d/1kH5v4G0q7w6Ynm4PQ3TyAAiCMxlG3ufr/view", "_blank");
      break;
  }

  return commands.get(name) ?? `${name}: command not found`;
};

export const getCommandNames = (): string[] => {
  const commandNames: string[] = ["clear"];
  for (const entry of Array.from(commands.entries())) {
    commandNames.push(entry[0]);
  }

  return commandNames.sort();
};

export function motdText(): string {
  return `
    Welcome to my Vansh Damania's Terminal!<br>
    <br>&nbsp;* Type 'help' to see the list of available commands.
  `;
}

function helpText(): string {
  const commandNames: string[] = ["clear", "help"];
  for (const entry of Array.from(commands.entries())) {
    commandNames.push(entry[0]);
  }

  return `
    Usage: [command] [options]
    <br>
    <br>

    ${commandNames.sort().join(", ")}
  `;
}

function aboutText(): string {
  return `
    I'm Vansh Hitesh Damania, a third-year Engineering student at DJ Sanghvi College of Engineering 
    pursuing my Computer Science degree. If breaking security and making developers cry were Olympic sports, 
    I'd be a gold medalist. Yep, you heard it right - I've got a knack for <span class="terminal-bold">penetration testing</span> and <span class="terminal-bold">VAPT</span> that keeps me up at night, 
    ensuring systems are as secure as Fort Knox (or at least trying to).<br><br>

    Sometimes developers need a hero, right? As an <span class="terminal-bold">active learner</span>, I understand the blood, sweat, and tears 
    that go into crafting code, which is why I'm committed to helping developers sleep soundly at night. 
    Whether it's tackling <span class="terminal-bold"> infrastructure challenges, optimizing deployment pipelines, monitoring system 
    resources, or simply lending a hand in debugging</span>, consider me your trusty sidekick in the world of 
    development. <br><br>

    So if you're ready to level up your <span class="terminal-bold">security, supercharge your development workflow, or just swap stories 
    about the latest tech</span>, let's connect and make some magic happen.<br><br>

    Would you like to connect with me? Enter the 'socials' command!
  `;
}

function openLink(link: string): string {
  return `
    Redirecting to 
      <a class="terminal-link" href="${link}" target="_blank" rel="noreferrer">
        ${link}</a>...
    `;
}

function projectsText(): string {
  return `
    ${projects
      .map((project) => {
        return `
        <a 
          class="project-${project.category}"
          href="${project.link}"
          target="_blank"
          rel="noreferrer"
        >${project.name}</a>`;
      })
      .join("&nbsp;&nbsp;&nbsp;")}
  `;
}

function kaliLogo() {
  return `
    <span class="text-kali-blue flex flex-col">
      <span>${"..............".replaceAll(" ", "&nbsp;")}</span>
      <span>${"            ..,;:ccc,.".replaceAll(" ", "&nbsp;")}</span>
      <span>${"          ......''';lxO.".replaceAll(" ", "&nbsp;")}</span>
      <span>${".....''''..........,:ld;".replaceAll(" ", "&nbsp;")}</span>
      <span>${"           .';;;:::;,,.x,".replaceAll(" ", "&nbsp;")}</span>
      <span>${"      ..'''.            0Xxoc:,.  ...".replaceAll(
        " ",
        "&nbsp;"
      )}</span>
      <span>${"  ....                ,ONkc;,;cokOdc',.".replaceAll(
        " ",
        "&nbsp;"
      )}</span>
      <span>${" .                   OMo           ':ddo.".replaceAll(
        " ",
        "&nbsp;"
      )}</span>

      <span>${"                    dMc               :OO;".replaceAll(
        " ",
        "&nbsp;"
      )}</span>
      <span>${"                    0M.                 .:o.".replaceAll(
        " ",
        "&nbsp;"
      )}</span>
      <span>${"                    ;Wd".replaceAll(" ", "&nbsp;")}</span>
      <span>${"                     ;XO,".replaceAll(" ", "&nbsp;")}</span>
      <span>${"                       ,d0Odlc;,..".replaceAll(
        " ",
        "&nbsp;"
      )}</span>
      <span>${"                            ..',;:cdOOd::,.".replaceAll(
        " ",
        "&nbsp;"
      )}</span>
      <span>${"                                     .:d;.':;.".replaceAll(
        " ",
        "&nbsp;"
      )}</span>
      <span>${"                                        'd,  .'".replaceAll(
        " ",
        "&nbsp;"
      )}</span>
      <span>${"                                          ;l   ..".replaceAll(
        " ",
        "&nbsp;"
      )}</span>
      <span>${"                                           .o".replaceAll(
        " ",
        "&nbsp;"
      )}</span>
      <span>${"                                             c".replaceAll(
        " ",
        "&nbsp;"
      )}</span>
      <span>${"                                             .'".replaceAll(
        " ",
        "&nbsp;"
      )}</span>
      <span>${"                                              .".replaceAll(
        " ",
        "&nbsp;"
      )}</span>
    </span>
  `;
}
